import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { InboxService, NavigationService } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import { Store } from '@ngrx/store';
import { PWCNavigationClientConfig } from '@pokercore/module/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';
import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';

import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { GoBackDirective } from '../../pipes/go-back.directive';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PokerWebClientSharedService } from '../../poker-web-client-shared.service';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { EventDispatcherGlobal } from '../../service/event-despacher-global';
import { PokerWebClientLoginService } from '../../service/poker-web-client-login.service';
import { GameTableSettingsSelect, RaiseButtonPostFlopSelect, RaiseButtonPreFlopSelect } from '../../store/game-table-settings.store';
import { registeredTourneysList } from '../../store/lobby.store';
import { updateIsCashBackEnabledStatus } from '../../store/loyalty-cashback.store';
import { miniTablesSelect } from '../../store/mini-table.store';
import { userBalanceSelect, userIsAuthenticatedSelect, userProfileSelect, userSSOSelect, userTicketSelect } from '../../store/user.store';
import { ClientHeaderBalanceComponent } from '../client-header-balance/client-header-balance.component';
import { ClientLHNUserInfoComponent } from '../client-lhn-user-info/client-lhn-user-info.component';
import { ClientSubHeaderComponent } from '../client-sub-header/client-sub-header.component';
import { GameTableSideNavigationComponent } from '../game-table-side-navigation/game-table-side-navigation.component';
import { PWCLoyaltyCashbackComponent } from '../loyalty-cashback/pwc-loyalty-cashback.component';
import { MiniTablesComponent } from '../mini-tables/mini-table.component';

@Component({
    selector: 'pwc-client-header',
    templateUrl: 'client-header.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MiniTablesComponent,
        GameTableSideNavigationComponent,
        TranslatePipe,
        GoBackDirective,
        FormatamountPipe,
        ClientHeaderBalanceComponent,
        ClientSubHeaderComponent,
        PWCLoyaltyCashbackComponent,
        ClientLHNUserInfoComponent,
    ],
    providers: [],
})
export class ClientHeaderComponent extends PCComponent implements OnInit, OnDestroy {
    headerLogo: any;
    public isInboxEnabled: boolean = false;
    public messagesCount: any = 0;
    // public userInfo = {
    //     isLoggedin: false,
    //     balance: 0,
    //     inPlayAmount: 0,
    //     accountCurrency: '',
    //     screenName: '',
    //     messagesCount: 0
    // };
    public menuItems: any;
    otherApps: Array<object> = [];
    public sideNavToggle: boolean = false;
    moneyTypeForm: FormGroup;
    // userInfoCheck: any;
    showMiniTables = false;
    isGameTableOpen = false;
    showBackButton = false;
    isPortalPageActive = false;
    routeSubscription!: Subscription;
    pageTitle: string;
    userIsLoggedIn = false;
    userName: string;
    accountBalance = 0;
    accountCurrency = 'USD';
    tourneyCurrencyBalance = 0;
    userTickets: number = 0;
    selectedLhnMenuItem = '';
    // headerWidget: boolean = false;
    // hideHamburger: boolean = true;
    showCashBackPopUp = false;
    private readonly logger: PokerLogger;
    private eventDispatcherGlobal = new EventDispatcherGlobal();
    gameInTileView: boolean;
    private unsubscribe = new Subject<void>();
    myTourneyCount: number = 0;

    constructor(
        private router: Router,
        loggerFactory: LoggerFactory,
        private inboxService: InboxService,
        private pwcNavigationClientConfig: PWCNavigationClientConfig,
        private renderer: Renderer2,
        private moneyTypeFormBuilder: FormBuilder,
        private navigation: NavigationService,
        private sharedService: PokerWebClientSharedService,
        private store: Store,
        private clientToServerRequestServices: ClientToServerRequestServices,
        private pokerWebClientLoginService: PokerWebClientLoginService,
    ) {
        super();
        this.logger = loggerFactory.getLogger('ClientHeaderLoggingComponent');
        this.headerLogo = this.clientToServerRequestServices.webAssets['lobby']['common']['header-logo'];
        this.clientToServerRequestServices.getPageTitle.subscribe((value) => {
            this.pageTitle = value;
        });

        this.inboxService.whenReady.subscribe(() => {
            this.isInboxEnabled = this.inboxService.isEnabled;
            // this.messagesCount = this.inboxService.count;
            this.inboxService.count.pipe(takeUntil(this.unsubscribe)).subscribe((count: number) => {
                this.messagesCount = count || 0;
                // this.inboxMessagesText = this.item.resources['NewMessagesText']!.replace('_COUNT_', this.messagesCount.toString());
            });
        });

        this.menuItems = this.pwcNavigationClientConfig?.items?.filter((item) => item.name !== 'bottomnavigation');
        const bottomNavigation = this.pwcNavigationClientConfig?.items?.find((item) => item.name === 'bottomnavigation');
        bottomNavigation?.children?.forEach((item) => {
            if (item.name === 'casino' || item.name === 'sports') {
                this.otherApps.push(item);
            }
        });
        this.store.dispatch(
            updateIsCashBackEnabledStatus({
                isCashBackEnabled: this.clientToServerRequestServices.dynaconConfigs['webClientConfigs']['loyaltyCashBack']['isLoyaltyEnabled'],
            }),
        );

        this.getStoreValues();
        // this.eventDispatcherGlobal.addEventListener('gameEventListener', this.getEventClass.bind(this));
        this.eventDispatcherGlobal.addEventListener('showLoyaltyCashBackPopUp', this.showLoyaltyCashBackPopUp.bind(this));
        this.eventDispatcherGlobal.addEventListener('gameEventListener', this.getTileViewStatus.bind(this));
    }

    ngOnInit(): void {
        this.router.events.subscribe(() => {
            this.isGameTableOpen = this.router.url.includes('/play/game-table');
            this.logger.info('Is game table opened:' + this.isGameTableOpen);
            this.showBackButton = this.router.url.includes('/play/');
            this.isPortalPageActive = this.router.url.includes('/play/portal');
            // this.headerWidget = this.isGameTableOpen;
        });
        // this.router.events.pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
        //  ).subscribe((e: RouterEvent) => {
        //     console.log('@@..RouterEvent..',e);
        //     this.isGameTableOpen = e.url.includes('/play/game-table');
        //     this.showBackButton = e.url.includes('/play/');
        //     this.isPortalPageActive = e.url.includes('/play/portal');
        // });

        this.renderer.addClass(document.body, 'pwc-page');
        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.renderer.addClass(document.body, 'pwc-page-standalone');
        }
        this.renderer.addClass(document.body, this.checkDeviceType());

        this.moneyTypeForm = this.moneyTypeFormBuilder.group({
            // moneyTypeCheck:[{}]
            moneyTypeCheck: false, // Initialize the checkbox value
        });
        this.moneyTypeForm.controls.moneyTypeCheck.disable();
        // this.moneyTypeForm.valueChanges.subscribe((formValue) => {
        //     const moneyTypeCheck = formValue.moneyTypeCheck;
        // });
        this.eventDispatcherGlobal.dispatchEvent('UpdateLHNStatus', { status: this.sideNavToggle });
        this.getTourneyCount();
        this.showWidget();
    }

    openLogin() {
        this.logger.info('clicked on login button..!');
        this.sideNavToggle = false;
        this.pokerWebClientLoginService.requestForPortalLogin();
    }

    openReg() {
        this.logger.info('clicked on Register button..!');
        this.sideNavToggle = false;
        this.pokerWebClientLoginService.requestForPortalRegistration();
    }

    showInbox() {
        this.logger.info('clicked on inbox icon..!');
        // if (this.isInboxEnabled) {
        //     this.inboxService.open({
        //         showBackButton: false,
        //         trackingEventName: 'Event.inbox.clicked_icon',
        //     });
        // } else {
        //     alert('No messages are currently available.');
        // }
        let ssoKey;
        this.store.select(userSSOSelect).subscribe((data) => {
            ssoKey = data;
        });
        this.sharedService.sendPortalPage({
            text: this.clientToServerRequestServices.externalLinks['inbox'].title,
            url: this.updateInboxUrl(this.clientToServerRequestServices.externalLinks['inbox']['titleLink']['url'], ssoKey),
        });
        this.navigation.goTo('/play/' + 'portal');
    }

    updateInboxUrl(originalUrl: string, ssoKey: string): string {
        // Use regex to remove "promo/" before "inbox"
        const updatedUrl = originalUrl.replace(/\/promo(?=\/inbox)/, '');
        // Append the ssoKey
        return updatedUrl + '&_sso=' + ssoKey;
    }

    // Open and Close Side Navigation
    toggleSideNav() {
        this.logger.info('clicked on sidenav button..!');
        this.sideNavToggle = this.sideNavToggle ? false : true;
        this.eventDispatcherGlobal.dispatchEvent('UpdateLHNStatus', { status: this.sideNavToggle });
    }

    openPortalPage(activePage: any) {
        this.logger.info('clicked on meny item like tickets,offers ect.....!' + JSON.stringify(activePage));
        localStorage.setItem('isATHAlertShown', 'false');
        this.toggleSideNav();
        if (activePage.parameters && activePage.parameters.isLoginRequired === 'true') {
            if (this.userIsLoggedIn) {
                if (activePage.name === 'logout') {
                    this.pokerWebClientLoginService.requestForPortalLogOut();
                } else if (activePage.name === 'hand-history') {
                    this.sharedService.setPageHeaderTitle({ title: activePage.text, url: '/play', showBalanceBlock: false });
                    this.navigation.goTo('/play/handhistory');
                } else if (activePage.name === 'tickets') {
                    this.sharedService.setPageHeaderTitle({ title: activePage.text, url: '/play', showBalanceBlock: false });
                    this.navigation.goTo('/play/tickets');
                } else {
                    this.sharedService.sendPortalPage(activePage);
                    this.navigation.goTo('/play/' + 'portal');
                }
            } else {
                this.openLogin();
            }

            // } else if (activePage.name === "game-rules"){
            //     this.sharedService.sendPortalPage(activePage);
            //     this.navigation.goTo('/play/how-to-play');
        } else {
            if (activePage.name === 'tutorial') {
                this.sharedService.setPageHeaderTitle({ title: activePage.text, url: '/play', showBalanceBlock: false });
                this.navigation.goTo('/play/tutorial?invokerapp=AW');
            } else {
                this.sharedService.sendPortalPage(activePage);
                this.navigation.goTo('/play/' + 'portal');
            }
        }

        // add active class to the Menu Item(LHN), if the page open from Footer remove it.
        let title = '';
        this.sharedService.getPageHeaderTitle().subscribe((info) => {
            if (info) {
                title = info.title;
            }
        });
        this.router.events.subscribe(() => {
            let routIsActive = this.isPortalPageActive || this.router.url.includes('/play/handhistory') || this.router.url.includes('/play/tickets');
            this.selectedLhnMenuItem = routIsActive && activePage.text === title ? activePage.name : 'null';
        });
    }

    goToOtherApp(item: any) {
        this.logger.info('clicked on casino,sports buttons..!' + JSON.stringify(item));
        this.toggleSideNav();
        this.sharedService.sendPortalPage(item);
        this.sharedService.sendPortalPage({
            ...item,
            showBalanceBlock: this.userIsLoggedIn === true ? true : false,
        });
        this.navigation.goTo('/play/' + 'portal');
    }

    // goBack(){
    //     this.navigation.goTo('/play');
    // }

    getStoreValues() {
        combineLatest([
            this.store.select(userIsAuthenticatedSelect),
            this.store.select(userProfileSelect),
            this.store.select(userBalanceSelect),
            this.store.select(miniTablesSelect),
            this.store.select(userTicketSelect),
            this.store.select(GameTableSettingsSelect),
            this.store.select(RaiseButtonPreFlopSelect),
            this.store.select(RaiseButtonPostFlopSelect),
        ]).subscribe(([authState, userProfile, userBalance, miniTables, userTicket, GameTableSettings, preFlopData, postFlopData]) => {
            this.userIsLoggedIn = authState;
            this.userName = userProfile['screenName'];
            // this.userBalanceInfo = userBalance;
            this.showMiniTables = miniTables['miniTables'].length > 0 ? true : false;

            if (!_.isEmpty(userBalance) && !_.isEmpty(userBalance['netRealBalance']) && !_.isEmpty(userBalance['tourneyCurrencyBalance'])) {
                this.accountCurrency = userBalance['netRealBalance']['accountCurrency'];
                this.accountBalance = userBalance['netRealBalance']['accountBalance'];
                this.tourneyCurrencyBalance = userBalance['tourneyCurrencyBalance']['tourneyCurrencyBalance'];
            }
            if (userTicket) {
                this.userTickets = userTicket;
                // if (userTicket['MESSAGE_NAME'] === "UserFreeRollList") {
                //     this.userTickets = userTicket['freeRollIds'].length;
                // } else if (userTicket['MESSAGE_NAME'] === "RemovedFreeRoll") {
                //     this.userTickets = this.userTickets - userTicket['freeRollId'].length;
                // } else if (userTicket['MESSAGE_NAME'] === "AddedFreeRoll") {
                //     this.userTickets = userTicket['freeRollId'].length;
                // }
            }

            const gsData = localStorage.getItem('gameSettingData');
            const preFData = localStorage.getItem('PreFlopData');
            const postFData = localStorage.getItem('PostFlopData');

            if (gsData === null) {
                localStorage.setItem('gameSettingData', JSON.stringify(GameTableSettings));
            }
            // if(JSON.parse(localStorage.getItem("pwcRaiseBtnSettings")) == null){
            //     localStorage.setItem("pwcRaiseBtnSettings", JSON.stringify(RaiseBtnSettings));
            // }

            if (preFData === null) {
                localStorage.setItem('PreFlopData', JSON.stringify(preFlopData));
            }
            if (postFData == null) {
                localStorage.setItem('PostFlopData', JSON.stringify(postFlopData));
            }
        });
    }

    // getEventClass(message: any) {
    //     if (message.detail.className === 'showWidget') {
    //         this.gameInTileView = message.detail.show;
    //         this.headerWidget = (this.isGameTableOpen || this.showMiniTables) && message.detail.show;
    //         if (this.isGameTableOpen && this.showMiniTables) {
    //             if (!message.detail.show) {
    //                 this.hideHamburger = false;
    //             } else {
    //                 this.hideHamburger = true;
    //             }
    //         } else if (!this.isGameTableOpen && this.showMiniTables) {
    //             if (!this.headerWidget) {
    //                 this.hideHamburger = false;
    //             } else {
    //                 this.hideHamburger = true;
    //             }
    //         }
    //     }
    // }

    ngOnDestroy(): void {
        // Remove class from <body> tag when component is destroyed
        this.renderer.removeClass(document.body, 'pwc-page');
        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.renderer.removeClass(document.body, 'pwc-page-standalone');
        }
        this.renderer.removeClass(document.body, this.checkDeviceType());
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    showLoyaltyCashBackPopUp() {
        this.showCashBackPopUp = true;
    }

    closeCashBackPopUp() {
        this.showCashBackPopUp = false;
    }

    getTileViewStatus(message: any) {
        if (message.detail.className === 'showWidget') {
            this.gameInTileView = message.detail.show;
        }
    }
    showWidget() {
        const message = {};
        message['className'] = 'showWidget';
        // message["tableId"] = peerId;
        message['show'] = true;
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    showTourneys() {
        this.navigation.goTo('/play/myTourneys');
    }

    getTourneyCount() {
        this.store.select(registeredTourneysList).subscribe((data) => {
            this.myTourneyCount = data?.sngJPInstanceDetails?.length > 0 ? data.sngJPInstanceDetails.length : 0;
        });
    }

    checkDeviceType() {
        let userAgent = navigator.userAgent;

        if (/(iPad|iPhone|iPod)/g.test(userAgent)) {
            return 'pwc-ios';
        } else if (/(Android)/g.test(userAgent)) {
            return 'pwc-an';
        } else {
            return 'pwc-desktop';
        }
    }
}
