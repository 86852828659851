import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

import { Store } from '@ngrx/store';
// import { PWCNavigationClientConfig } from '@pokercore/module/core';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';

import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { userBalanceSelect, userProfileSelect } from '../../store/user.store';

@Component({
    selector: 'pwc-client-lhn-user-info',
    templateUrl: 'client-lhn-user-info.component.html',
    standalone: true,
    imports: [CommonModule, FormatamountPipe, TranslatePipe],
    providers: [],
})
export class ClientLHNUserInfoComponent {
    userName: string;
    accountBalance = 0;
    accountCurrency = 'USD';
    // bottomNavigation: any;
    private readonly logger: PokerLogger;
    @Output() emitSideNavToggle: EventEmitter<any> = new EventEmitter();
    balanceIcon: string;

    constructor(
        private store: Store,
        // private pwcNavigationClientConfig: PWCNavigationClientConfig,
        loggerFactory: LoggerFactory,
        private clientToServer: ClientToServerRequestServices,
    ) {
        this.logger = loggerFactory.getLogger('ClientLHNUserInfoComponent');
        this.balanceIcon = this.clientToServer.webAssets['lobby']['common']['header-balance-icon']['image']['src'];
        this.store.select(userProfileSelect).subscribe((userProfile) => {
            this.userName = userProfile['screenName'];
        });
        this.store.select(userBalanceSelect).subscribe((userBalance) => {
            if (!_.isEmpty(userBalance) && !_.isEmpty(userBalance['netRealBalance']) && !_.isEmpty(userBalance['tourneyCurrencyBalance'])) {
                this.accountCurrency = userBalance['netRealBalance']['accountCurrency'];
                this.accountBalance = userBalance['netRealBalance']['accountBalance'];
            }
        });
        // this.bottomNavigation = this.pwcNavigationClientConfig?.items.find((item) => item.name === 'bottomnavigation');
    }

    openCashierPage() {
        this.logger.info('clicked on cashier button..!');
        this.emitSideNavToggle.emit();
        this.clientToServer.openDepositPage('cashier');
    }
}
