<div class="main-container">
    <div class="loader-parent">
        <div id="demo" class="childLoader">
            <div class="loderVerticalFlex">
                <div class="loader_img w-25">
                    <img
                        class="preLoader_img"
                        src="https://help.partypoker.com/~/media/Assets/PokerWebClient/poker-web-client/lobby/common/preloader_PP.gif"
                        alt="loader" />
                </div>
                <!-- displaying "Connecting..." and "Disconnecting..." text -->
                <div class="connect_text">
                    {{ txtToBeDisplayed }}
                </div>
            </div>
        </div>
    </div>
</div>
