<header class="pwc-header-main" [ngClass]="{ 'user-authenticated': userIsLoggedIn, 'pwc-mini-tables-header': showMiniTables }">
    <div class="pwc-brand-header" [class.gameInTileView]="!gameInTileView">
        <button (click)="toggleSideNav()" class="pwc-menu-btn ui-icon ui-icon-size-lg theme-menu"></button>
        <div class="pwc-logo" *ngIf="!showMiniTables">
            <img [src]="headerLogo.image.src" [attr.alt]="headerLogo.title" />
        </div>
        <pwc-mini-tables class="mini-tables-wrapper" *ngIf="showMiniTables"></pwc-mini-tables>
    </div>
    <div class="pwc-user-block" *ngIf="!isGameTableOpen">
        <ng-container *ngIf="!userIsLoggedIn && !showBackButton; else UserInformation">
            <div class="row m-0">
                <div class="col-6 pl-0">
                    <button (click)="openReg()" class="btn btn-md btn-primary text-capitalize w-100">
                        {{ 'PARTY_POKER_GC_REGISTER' | translate }}
                    </button>
                </div>
                <div class="col-6 pr-0">
                    <button (click)="openLogin()" class="btn btn-md btn-primary text-capitalize w-100">
                        {{ 'PARTY_POKER_MOBILE_LOBBY_REDESIGN_LOGIN_LOGIN_NEW' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-template #UserInformation>
            <div class="row" [ngClass]="{ 'align-items-center': showBackButton }">
                <ng-container *ngIf="showBackButton; else hideBackButton">
                    <pwc-client-sub-header></pwc-client-sub-header>
                </ng-container>
                <ng-template #hideBackButton>
                    <div class="col-3 pl-0">
                        <div class="pwc-user-info" (click)="showInbox()">
                            <span class="user-badge">
                                <span *ngIf="messagesCount > 0" class="badge badge-circle badge-size-sm badge-offset badge-t-r badge-primary">{{
                                    messagesCount
                                }}</span>
                                <svg width="24" height="24" viewBox="0 -4 24 24" fill="none">
                                    <g clip-path="url(#clip0_306_5607)">
                                        <g filter="url(#filter0_d_306_5607)">
                                            <path
                                                d="M22.6029 8.39273V16.905C22.6029 18.7168 21.1847 20.1974 19.3979 20.2974L19.2051 20.3027H5.09123C3.2794 20.3027 1.79879 18.8846 1.69883 17.0978L1.69345 16.905V8.39273L11.7843 13.679C12.0122 13.7984 12.2841 13.7984 12.512 13.679L22.6029 8.39273ZM5.09123 3.5752H19.2051C20.9664 3.5752 22.4148 4.9154 22.586 6.63168L12.1482 12.0993L1.71038 6.63168C1.87522 4.97896 3.22435 3.67499 4.89686 3.58066L5.09123 3.5752H19.2051H5.09123Z"
                                                fill="#2961C0" />
                                            <path
                                                d="M22.6029 8.39273V16.905C22.6029 18.7168 21.1847 20.1974 19.3979 20.2974L19.2051 20.3027H5.09123C3.2794 20.3027 1.79879 18.8846 1.69883 17.0978L1.69345 16.905V8.39273L11.7843 13.679C12.0122 13.7984 12.2841 13.7984 12.512 13.679L22.6029 8.39273ZM5.09123 3.5752H19.2051C20.9664 3.5752 22.4148 4.9154 22.586 6.63168L12.1482 12.0993L1.71038 6.63168C1.87522 4.97896 3.22435 3.67499 4.89686 3.58066L5.09123 3.5752H19.2051H5.09123Z"
                                                fill="white"
                                                fill-opacity="0.6" />
                                        </g>
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_d_306_5607"
                                            x="-2.30655"
                                            y="3.5752"
                                            width="28.9094"
                                            height="24.7275"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_306_5607" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_306_5607" result="shape" />
                                        </filter>
                                        <clipPath id="clip0_306_5607">
                                            <rect width="23.8889" height="23.8889" fill="white" transform="translate(0.419098 0.211182)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="pwc-user-info" (click)="showTourneys()">
                            <span class="user-badge">
                                <span *ngIf="myTourneyCount > 0" class="badge badge-circle badge-size-sm badge-offset badge-t-r badge-primary">{{
                                    myTourneyCount
                                }}</span>
                                <svg width="26" height="28" viewBox="0 -6 28 24" fill="none">
                                    <g clip-path="url(#clip0_306_5591)">
                                        <g filter="url(#filter0_d_306_5591)">
                                            <path
                                                d="M23.917 3.72876C23.8161 3.2056 23.5624 2.72405 23.1881 2.3449C22.8137 1.96575 22.3355 1.706 21.8136 1.59843C20.9823 1.45017 20.1259 1.54377 19.3462 1.86809H19.1574V1.71978C19.1574 1.31528 19.1574 0.910787 19.1574 0.519775H4.83835C4.83835 0.910787 4.83835 1.31528 4.83835 1.71978V1.86809H4.64958C3.8699 1.54377 3.01349 1.45017 2.18217 1.59843C1.66033 1.706 1.18205 1.96575 0.807701 2.3449C0.433353 2.72405 0.179713 3.2056 0.0787954 3.72876C-0.258283 5.21191 0.48329 6.95124 2.22262 8.60966C3.70465 9.98456 5.45957 11.032 7.37318 11.6838C8.15473 12.821 9.23804 13.7175 10.5013 14.2726H10.6091V14.3804C10.2306 15.842 9.45061 17.1684 8.35745 18.2097H15.5844C14.4913 17.1684 13.7113 15.842 13.3327 14.3804V14.2726H13.4406C14.7038 13.7175 15.7871 12.821 16.5687 11.6838C18.5015 11.0389 20.2754 9.99105 21.7732 8.60966C23.5125 6.95124 24.2676 5.21191 23.917 3.72876ZM5.9979 9.36472C5.01205 8.87549 4.10347 8.24403 3.30127 7.49056C2.75603 6.97637 2.30034 6.37487 1.95295 5.71079C1.67861 5.21889 1.57881 4.64863 1.66981 4.09281C1.71164 3.83615 1.83309 3.59913 2.01697 3.41525C2.20085 3.23137 2.43787 3.10993 2.69453 3.06809C3.48006 2.95829 4.27827 3.14583 4.93273 3.59393V3.66135C5.07072 5.54694 5.47472 7.40353 6.13273 9.17596L6.25408 9.49955L5.9979 9.36472ZM22.2586 5.72427C21.9112 6.38835 21.4555 6.98986 20.9103 7.50405C20.1235 8.24578 19.2388 8.87635 18.281 9.3782L17.9844 9.52652L18.1058 9.20292C18.7656 7.44102 19.1653 5.5924 19.2923 3.71528V3.64787C19.9538 3.18026 20.7687 2.98256 21.5709 3.09506C21.8105 3.14327 22.0308 3.26043 22.2046 3.43214C22.3747 3.62824 22.4909 3.86521 22.5417 4.11978C22.6235 4.67116 22.5241 5.23418 22.2586 5.72427Z"
                                                fill="#2961C0" />
                                            <path
                                                d="M23.917 3.72876C23.8161 3.2056 23.5624 2.72405 23.1881 2.3449C22.8137 1.96575 22.3355 1.706 21.8136 1.59843C20.9823 1.45017 20.1259 1.54377 19.3462 1.86809H19.1574V1.71978C19.1574 1.31528 19.1574 0.910787 19.1574 0.519775H4.83835C4.83835 0.910787 4.83835 1.31528 4.83835 1.71978V1.86809H4.64958C3.8699 1.54377 3.01349 1.45017 2.18217 1.59843C1.66033 1.706 1.18205 1.96575 0.807701 2.3449C0.433353 2.72405 0.179713 3.2056 0.0787954 3.72876C-0.258283 5.21191 0.48329 6.95124 2.22262 8.60966C3.70465 9.98456 5.45957 11.032 7.37318 11.6838C8.15473 12.821 9.23804 13.7175 10.5013 14.2726H10.6091V14.3804C10.2306 15.842 9.45061 17.1684 8.35745 18.2097H15.5844C14.4913 17.1684 13.7113 15.842 13.3327 14.3804V14.2726H13.4406C14.7038 13.7175 15.7871 12.821 16.5687 11.6838C18.5015 11.0389 20.2754 9.99105 21.7732 8.60966C23.5125 6.95124 24.2676 5.21191 23.917 3.72876ZM5.9979 9.36472C5.01205 8.87549 4.10347 8.24403 3.30127 7.49056C2.75603 6.97637 2.30034 6.37487 1.95295 5.71079C1.67861 5.21889 1.57881 4.64863 1.66981 4.09281C1.71164 3.83615 1.83309 3.59913 2.01697 3.41525C2.20085 3.23137 2.43787 3.10993 2.69453 3.06809C3.48006 2.95829 4.27827 3.14583 4.93273 3.59393V3.66135C5.07072 5.54694 5.47472 7.40353 6.13273 9.17596L6.25408 9.49955L5.9979 9.36472ZM22.2586 5.72427C21.9112 6.38835 21.4555 6.98986 20.9103 7.50405C20.1235 8.24578 19.2388 8.87635 18.281 9.3782L17.9844 9.52652L18.1058 9.20292C18.7656 7.44102 19.1653 5.5924 19.2923 3.71528V3.64787C19.9538 3.18026 20.7687 2.98256 21.5709 3.09506C21.8105 3.14327 22.0308 3.26043 22.2046 3.43214C22.3747 3.62824 22.4909 3.86521 22.5417 4.11978C22.6235 4.67116 22.5241 5.23418 22.2586 5.72427Z"
                                                fill="white"
                                                fill-opacity="0.6" />
                                        </g>
                                        <g filter="url(#filter1_d_306_5591)">
                                            <path d="M16.9889 19.6389H7.01141V21.5805H16.9889V19.6389Z" fill="#2961C0" />
                                            <path d="M16.9889 19.6389H7.01141V21.5805H16.9889V19.6389Z" fill="white" fill-opacity="0.6" />
                                        </g>
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_d_306_5591"
                                            x="-4.00052"
                                            y="0.519775"
                                            width="32.0014"
                                            height="25.6899"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_306_5591" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_306_5591" result="shape" />
                                        </filter>
                                        <filter
                                            id="filter1_d_306_5591"
                                            x="3.01141"
                                            y="19.6389"
                                            width="17.9775"
                                            height="9.94165"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_306_5591" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_306_5591" result="shape" />
                                        </filter>
                                        <clipPath id="clip0_306_5591">
                                            <rect width="24" height="21.0607" fill="white" transform="translate(-0.00195312 0.519775)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-6 pr-0">
                        <pwc-client-header-balance *ngIf="userIsLoggedIn"></pwc-client-header-balance>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </div>
</header>

<!-- Side Navigation -->
<div class="pwc-side-navigation-wrap" [ngClass]="{ open: sideNavToggle }">
    <div class="side-nav-overlay" (click)="toggleSideNav()">&nbsp;</div>
    <div class="side-nav-content" [class.d-none]="isGameTableOpen">
        <div class="auth-block" [ngClass]="{ 'authenticated-user': userIsLoggedIn }">
            <ng-container *ngIf="!userIsLoggedIn; else UserInformationSideNav">
                <div class="row">
                    <div class="col-6">
                        <button (click)="openReg()" class="btn btn-primary btn-md text-capitalize w-100">
                            {{ 'PARTY_POKER_GC_REGISTER' | translate }}
                        </button>
                    </div>
                    <div class="col-6">
                        <button (click)="openLogin()" class="btn btn-primary btn-md text-capitalize w-100">
                            {{ 'PARTY_POKER_MOBILE_LOBBY_REDESIGN_LOGIN_LOGIN_NEW' | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-template #UserInformationSideNav>
                <pwc-client-lhn-user-info (emitSideNavToggle)="toggleSideNav()"></pwc-client-lhn-user-info>
            </ng-template>
        </div>
        <div class="menu-items-wrap">
            <ng-container *ngIf="menuItems">
                <div class="menu-group" *ngFor="let item of menuItems">
                    <h4 class="menu-group-title">{{ item?.text }}</h4>
                    <ul *ngIf="item?.children">
                        <li *ngFor="let link of item?.children">
                            <ng-container *ngIf="link?.name !== 'logout'; else logoutMenuItem">
                                <a
                                    (click)="openPortalPage(link)"
                                    [ngClass]="['menu-item', link?.class]"
                                    [class.active]="selectedLhnMenuItem === link?.name"
                                    ><span>{{ link?.text }}</span></a
                                >
                            </ng-container>
                            <ng-template #logoutMenuItem>
                                <a *ngIf="userIsLoggedIn" (click)="openPortalPage(link)" class="menu-item" [ngClass]="link?.class"
                                    ><span>{{ link?.text }}</span></a
                                >
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
        <div class="other-apps-block">
            <h4 class="menu-group-title">{{ 'PARTY_POKER_MOBILE_LOBBY_REDESIGN_LHN_TRY_OUR_OTHER_APPS' | translate }}</h4>
            <div class="row">
                <div class="col-6" *ngFor="let item of otherApps">
                    <button (click)="goToOtherApp(item)" class="btn pwc-btn-apps w-100 d-flex justify-content-center align-items-center">
                        <span class="app-item-icon">
                            <img [src]="item.image.src" alt="itemImage" />
                        </span>
                        <span>{{ item.text }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="side-nav-content" [class.d-none]="!isGameTableOpen">
        <pwc-game-table-side-navigation (emitSideNavToggle)="toggleSideNav()"></pwc-game-table-side-navigation>
    </div>
</div>

<div class="pwc-loyalty-cashback-overlay" *ngIf="showCashBackPopUp" (click)="closeCashBackPopUp()">
    <pwc-loyalty-cashback *ngIf="showCashBackPopUp" (close)="closeCashBackPopUp()"></pwc-loyalty-cashback>
</div>
