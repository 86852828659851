import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { FormatamountPipe } from '../../pipes/formatAmount.pipe';
import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';
import { GameTableNotificationService } from '../../service/game-table-notification.service';
import { AthBlockTime, updateAthBlockTime } from '../../store/lobby.store';

@Component({
    selector: 'pwc-cash-lobby-tile',
    templateUrl: 'cash-lobby-tile.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe, formatCurrencyPipe, FormatamountPipe],
    providers: [],
})
export class CashLobbyTileComponent implements OnInit {
    @Input() tileData: any;
    @Input() cardIndex: number;
    selectedIndex = -1;
    public athBlockTime = 0;
    @Output() cardSelected: EventEmitter<any> = new EventEmitter();
    isATHEnabled: boolean;
    private readonly logger: PokerLogger;
    lockicon: any;
    cashLobbyTileBg: any;

    constructor(
        private clientToServer: ClientToServerRequestServices,
        loggerFactory: LoggerFactory,
        private store: Store,
        private gameTableNotificationService: GameTableNotificationService,
    ) {
        this.isATHEnabled = this.clientToServer.dynaconConfigs['webClientConfigs']['enableATH'];
        this.lockicon = this.clientToServer.webAssets['lobby']['common']['athlockicon'];
        this.cashLobbyTileBg = this.clientToServer.webAssets['lobby']['common']['cashlobbytilebg'];
        this.logger = loggerFactory.getLogger('CashLobbyTileLoggingComponent');
    }
    ngOnInit() {
        this.store.select(AthBlockTime).subscribe((data) => {
            this.athBlockTime = data;
        });
        if (this.athBlockTime > 0) {
            this.removePanelityBG(this.athBlockTime);
        }
    }

    selectedCard(index: number) {
        this.logger.info('clicked on selectedCards..!' + JSON.stringify(index));
        this.selectedIndex = index;
        this.cardSelected.emit(this.cardIndex);
    }

    getLimitWithGameValue(value) {
        if (value.gameTypeId === 0) {
            //Hold’em
            if (value.tableLimitType === 0) {
                return 'FLH';
            } else if (value.tableLimitType === 1) {
                return 'NLH';
            } else {
                return 'PLH';
            }
        } else if (value.gameTypeId === 1 || value.gameTypeId === 3 || value.gameTypeId === 2 || value.gameTypeId === 4) {
            //omaha
            let result = '';

            if (value.tableLimitType === 0) {
                result = 'FLO';
            } else if (value.tableLimitType === 1) {
                result = 'NLO';
            } else {
                result = 'PLO';
            }

            if (value.gameTypeId === 2 || value.gameTypeId === 4) {
                return `${result}8`;
            } else {
                return result;
            }
        } else if (value.gameTypeId === 7) {
            //Shortdeck
            return 'Short Deck';
        } else {
            return;
        }
    }

    getColorCodeClass(value) {
        return value === 0 ? '#0B8FCA' : value > 0 && value < 5 ? '#008000' : '#CE001B';
    }

    // LIMIT_TYPE_REGULAR = 0
    // LIMIT_TYPE_NO_LIMIT = 1
    getLimitType(value) {
        if (value.tableLimitType === 0) {
            return 'Fixed Limit';
        } else if (value.tableLimitType === 1) {
            return 'No Limit';
        } else {
            return 'Pot Limit';
        }
    }

    removePanelityBG(timeStamp) {
        this.store.dispatch(updateAthBlockTime(timeStamp));
        const date = this.gameTableNotificationService.getTimeForPeer(0) * 1000;
        setTimeout(
            () => {
                this.store.dispatch(updateAthBlockTime({ Time: 0 }));
            },
            timeStamp * 1000 - date,
        );
    }
}
