<div class="col cashier-options-main">
    <div class="row">
        <div class="col-sm-12 col-md-4 mb-3 mb-md-0 px-2">
            <div class="card h-100 p-3 shadow-sm">
                <div>
                    <h5>
                        {{ widgetConfig?.countrySelectTitle?.part1 || 'Not your country?' }} <br />{{
                            widgetConfig?.countrySelectTitle?.part2 || 'Select your country from here'
                        }}
                    </h5>
                    <p-dropdown
                        (onChange)="refreshFeed($event)"
                        [options]="countries"
                        [(ngModel)]="selectedCountry"
                        [filter]="true"
                        filterMatchMode="startsWith"
                        optionLabel="name"
                        [showClear]="false"
                        placeholder="{{ widgetConfig?.selectCountry || 'Select a Country' }}">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="selectedCountry">
                                <span [class]="'flag flag-' + selectedCountry.code.toLowerCase()"></span>
                                <div>{{ selectedCountry.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <span [class]="'flag flag-' + country.code.toLowerCase()"></span>
                                <div>{{ country.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 px-2">
            <div class="card p-3 h-100 shadow-sm">
                <div>
                    <h5>{{ widgetConfig?.optionsFilterTitle || 'Show me only these options' }}</h5>
                    <div class="options-list d-flex" *ngIf="widgetOptions && widgetOptions.typesMap">
                        <div class="option-item" *ngFor="let type of widgetOptions.typesMap | keyvalue; let i = index">
                            <div class="form-element col-12">
                                <input
                                    #optionList
                                    (change)="filterPaymentOptions(type, $event)"
                                    type="checkbox"
                                    id="{{ 'widgetOptions' + i }}"
                                    checked
                                    class="ng-untouched ng-pristine ng-valid custom-control-input custom-control-checkbox" />
                                <label for="{{ 'widgetOptions' + i }}" class="font-weight-bold">{{ type.value }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Deposit Options -->
    <div class="deposit-options-cards mt-5" [class]="depositInfoClass" *ngIf="cashierOptions && cashierOptions?.optiondetails">
        <h5 class="deposit-sub-title" *ngIf="optionType === 'deposit'">
            {{ widgetConfig?.depositOptionsTitle || 'Click on a Deposit Option below to learn more' }}:
        </h5>
        <h5 class="deposit-sub-title" *ngIf="optionType === 'cashout'">
            {{ widgetConfig?.cashoutOptionsTitle || 'Click on a Cashout Option below to learn more' }}:
        </h5>
        <div class="row" *ngFor="let item of splitCashierOptions; let i = index">
            <div
                class="deposit-payment-card-col col-sm-3 col-6 mx-0 px-2 mb-3"
                *ngFor="let subItem of splitCashierOptions[i]; let childIndex = index">
                <div
                    class="deposit-payment-card-wrap card shadow-sm h-100 p-4"
                    [class]="{ active: subItem?.active }"
                    (click)="showCardInfor(subItem, i)">
                    <div class="deposit-payment-card">
                        <img
                            src="{{
                                'https://scmedia.itsfogo.com/vpp/PromoSites/partypoker.com/deposit-options/' + subItem.id.replace('pay_', '') + '.png'
                            }}"
                            alt="Promo Site" />
                        <span class="no-fee" *ngIf="compareFee(subItem.fee)">{{ widgetConfig?.noFee || 'No Fee' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-4 deposit-info-wrap deposit-info-wrap-{{ i + 1 }}" *ngIf="selectedCardInfo">
                <div class="card deposit-mode-info-card p-4 shadow-sm">
                    <div class="row">
                        <div class="col-sm-12 col-md-8">
                            <h5 class="mb-2">{{ selectedCardInfo?.name }}</h5>
                            <div [innerHtml]="selectedCardInfo?.description | trustAsHtml"></div>
                            <button class="btn btn-link pl-0" (click)="getFaq(selectedCardInfo.id)">
                                {{ widgetConfig?.faqCtaTest || 'Read the FAQ for more information' }}
                            </button>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <p *ngIf="optionType === 'deposit'">
                                <strong>{{ widgetConfig?.depositValuesTitle || 'Deposit values' }}:</strong>
                            </p>
                            <p *ngIf="optionType === 'cashout'">
                                <strong>{{ widgetConfig?.cashoutValuesTitle || 'Cashout values' }}:</strong>
                            </p>
                            <p class="deposit-values">
                                {{ widgetConfig?.min || 'Min' }}:
                                <span class="text-secondary"
                                    >{{ selectedCardInfo.currency | formatCurrencyPipe }}{{ selectedCardInfo?.txnLimits?.minimum }}</span
                                >
                            </p>
                            <p class="deposit-values">
                                {{ widgetConfig?.max || 'Max' }}:
                                <span class="text-secondary"
                                    >{{ selectedCardInfo.currency | formatCurrencyPipe }}{{ selectedCardInfo?.txnLimits?.maximum }}</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card deposit-no-results mt-4 p-3" *ngIf="!splitCashierOptions?.length">
            <h5 class="mb-0 text-center">{{ widgetConfig?.noResultsFound || 'No results found' }}</h5>
        </div>
    </div>

    <!-- Deposit Option Info Mobile -->
    <div class="col-12 mb-4 deposit-info-wrap-mobile" *ngIf="selectedCardInfo">
        <div class="card deposit-mode-info-card p-3 shadow-lg">
            <button class="btn btn-link deposit-info-close-btn" (click)="closeDepositInfo()"><span class="ui-icon theme-close-i"></span></button>
            <div class="row">
                <div class="col-sm-12 col-md-8 pb-3">
                    <h5 class="mb-2">{{ selectedCardInfo?.name }}</h5>
                    <div class="deposit-option-description-wrap">
                        <div [innerHtml]="selectedCardInfo?.description | trustAsHtml"></div>
                    </div>
                    <button class="btn btn-link p-0" (click)="getFaq(selectedCardInfo.id)">{{ widgetConfig?.faqsTitle || 'FAQs' }}</button>
                </div>
                <div class="col-sm-12 col-md-4">
                    <h6 class="deposit-values-title">Deposit values:</h6>
                    <p class="deposit-values">
                        {{ widgetConfig?.min || 'Min' }}:
                        <span class="text-secondary"
                            >{{ selectedCardInfo.currency | formatCurrencyPipe }}{{ selectedCardInfo?.txnLimits?.minimum }}</span
                        >
                    </p>
                    <p class="deposit-values">
                        {{ widgetConfig?.max || 'Max' }}:
                        <span class="text-secondary"
                            >{{ selectedCardInfo.currency | formatCurrencyPipe }}{{ selectedCardInfo?.txnLimits?.maximum }}</span
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
    <pk-cashier-options-faqs-modal
        *ngIf="faqServiceParams"
        [widgetConfig]="widgetConfig"
        [serviceParams]="faqServiceParams"
        (clearFaqs)="resetFaqs($event)"></pk-cashier-options-faqs-modal>
    <div class="loader" *ngIf="enableLoader">
        <span>{{ widgetConfig?.pleaseWait || 'Please Wait' }}</span>
    </div>
</div>
