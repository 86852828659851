import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { Page } from '@frontend/vanilla/core';
import { PCComponent } from '@frontend/vanilla/features/content';
import moment from 'moment';
import 'moment/locale/en-in';

@Component({
    selector: 'ppl-event-config',
    templateUrl: './ppl-event-config.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class PplEventConfigComponent extends PCComponent {
    @HostBinding()
    get class() {
        return this.createClass(this.item.name);
    }
    constructor(
        private router: Router,
        private page: Page,
    ) {
        super();
    }
    returnEventDate(startDate: string, endDate: string): string {
        const sDate = moment(startDate).format('Do');
        const eDate = moment(endDate).format('Do MMM YYYY');
        const sDateWithMonth = moment(startDate).format('Do MMM');
        const sDateWithMonthYear = moment(startDate).format('Do MMM YYYY');

        return moment(startDate).format('YYYY') === moment(endDate).format('YYYY')
            ? moment(startDate).format('MM') === moment(endDate).format('MM')
                ? `${sDate} - ${eDate}`
                : `${sDateWithMonth} - ${eDate}`
            : `${sDateWithMonthYear} - ${eDate}`;
    }
    gotoEvent(item: string) {
        this.router.navigate([this.page.lang + '/' + item]);
    }

    getCalendarUrl(obj: any) {
        let segmentUrl = obj.segmentUrl;
        const eventStartDate = moment(this.item.eventStartDate);
        const eventEndDate = moment(this.item.eventEndDate);
        const eventName = this.item.eventTitle.replace(/ /g, '+');
        segmentUrl = segmentUrl.replace('{{tourneyName}}', eventName);
        segmentUrl = segmentUrl.replace('{{tourneyStartDate}}', eventStartDate.format(obj.dateTimeFormat));
        segmentUrl = segmentUrl.replace('{{tourneyEndDate}}', eventEndDate.format(obj.dateTimeFormat));
        segmentUrl = segmentUrl.replace('{{labelName}}', 'partypoker.com');
        window.open(obj.hostUrl + segmentUrl, '_blank');
    }

    getSocialUrl(item: any) {
        const hostUrl = item.hostUrl.replace('{{pageUrl}}', window.location.href).replace('{{title}}', this.item.eventTitle);
        window.open(hostUrl, '_blank');
    }
}
