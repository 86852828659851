<div class="tile-card-container" (click)="selectedCard(cardIndex)" [attr.index]="cardIndex">
    <div class="tile-card-header" [style.border-color]="getColorCodeClass(tileData.gameTypeId)">
        <h4 class="title-card-title" [style.color]="getColorCodeClass(tileData.gameTypeId)">
            {{ getLimitWithGameValue(tileData) }} <span *ngIf="tileData.ffPool">fastforward</span>
        </h4>
    </div>
    <div class="tile-card-body">
        <div class="tile-stakes-ante">
            <span>
                <ng-container *ngIf="getLimitType(tileData) === 'Fixed Limit'; else elseTemplate">
                    {{ 'PARTY_POKER_GC_QSLOBBY_STAKE' | translate }}:
                </ng-container>
                <ng-template #elseTemplate>
                    <ng-container *ngIf="tileData.gameTypeId !== 7"> {{ 'PARTY_POKER_GC_QSLOBBY_QUERY_BLINDS' | translate }}: </ng-container>
                    <ng-container *ngIf="tileData.gameTypeId === 7"> {{ 'PARTY_POKER_GC_QSLOBBY_ANTE' | translate }}: </ng-container>
                </ng-template>

                <ng-container *ngIf="tileData.gameTypeId !== 7">
                    {{ tileData.smallBlind | formatCurrencyPipe: 'USD' }}
                    /
                    {{ tileData.bigBlind | formatCurrencyPipe: 'USD' }}
                </ng-container>
                <ng-container *ngIf="tileData.gameTypeId === 7">
                    {{ tileData.smallBlind | formatCurrencyPipe: 'USD' }}
                </ng-container>
            </span>
            <span *ngIf="tileData.anteCashAmount > 0">Ante: {{ tileData.anteCashAmount | formatCurrencyPipe: 'USD' }}</span>
        </div>
        <div class="tile-card-buyIn-container">
            <div class="tile-card-buyIn-background">
                <img class="cashLobbyTileBg" [src]="cashLobbyTileBg.image.src" [alt]="cashLobbyTileBg.title" />
            </div>
            <div class="tile-card-buyIn-buyIn">
                <span style="height: 15px"
                    ><span class="tile-card-realName" *ngIf="tileData.realNameTable">{{ 'PARTY_POKER_MOBILE_CASH_REALNAME' | translate }}</span></span
                >
                <div class="tile-card-text">
                    <span>
                        {{ 'PARTY_POKER_GC_BUYINCHIPS_TITLE' | translate }}
                    </span>
                    <span>{{ tileData.formattedBuyIn | formatCurrencyPipe: 'USD' }}</span>
                </div>
                <span class="tile-card-maxSeats"
                    >{{ tileData.maxNoOfSeats }}
                    {{ 'PARTY_POKER_GC_QSLOBBY_FAVSEATS' | translate }}
                </span>
            </div>
        </div>
    </div>
    <div class="tile-card-footer">
        <div class="tile-card-players">
            <span>
                <svg width="22" height="15" viewBox="0 0 22 15" fill="none">
                    <path
                        d="M11.0826 6.34976C12.6403 6.34976 13.903 5.07517 13.903 3.50288C13.903 1.9306 12.6403 0.656006 11.0826 0.656006C9.5249 0.656006 8.26215 1.9306 8.26215 3.50288C8.26215 5.07517 9.5249 6.34976 11.0826 6.34976Z"
                        fill="#434343" />
                    <path
                        d="M17.4161 6.27489C18.5776 6.27489 19.5191 5.32453 19.5191 4.15221C19.5191 2.97989 18.5776 2.02954 17.4161 2.02954C16.2547 2.02954 15.3132 2.97989 15.3132 4.15221C15.3132 5.32453 16.2547 6.27489 17.4161 6.27489Z"
                        fill="#434343" />
                    <path
                        d="M21.5974 11.2942C21.1768 8.62217 19.7913 7.02393 17.4162 7.02393C16.5008 7.02393 15.7586 7.24868 15.1401 7.69819C15.2143 7.7731 15.2885 7.84802 15.3875 7.92294C16.4019 8.99676 17.0946 10.5451 17.4162 12.5179C17.4162 12.5928 17.441 12.6677 17.441 12.7177C19.7418 12.7427 21.7706 12.3431 21.5974 11.2942Z"
                        fill="#434343" />
                    <path
                        d="M4.74889 6.27489C5.91032 6.27489 6.85185 5.32453 6.85185 4.15221C6.85185 2.97989 5.91032 2.02954 4.74889 2.02954C3.58746 2.02954 2.64594 2.97989 2.64594 4.15221C2.64594 5.32453 3.58746 6.27489 4.74889 6.27489Z"
                        fill="#434343" />
                    <path
                        d="M6.80243 7.92294C6.87665 7.84802 6.95087 7.7731 7.02509 7.69819C6.40658 7.24868 5.63962 7.02393 4.74895 7.02393C2.39859 7.02393 1.01311 8.5972 0.567778 11.2942C0.394593 12.3431 2.44807 12.7427 4.74895 12.7427C4.74895 12.6677 4.74895 12.5928 4.77369 12.5429C5.07058 10.57 5.76332 9.02174 6.80243 7.92294Z"
                        fill="#434343" />
                    <path
                        d="M11.0826 14.5908C7.98997 14.5908 5.2685 14.0414 5.49117 12.6679C6.08494 9.07182 7.94049 6.97412 11.0826 6.97412C14.2246 6.97412 16.0802 9.09679 16.674 12.6679C16.8966 14.0414 14.1751 14.5908 11.0826 14.5908Z"
                        fill="#434343" />
                </svg>
            </span>
            <span>{{ tileData.playersCount }}</span>
        </div>
        <div class="tile-card-tables">
            <span>
                <svg width="22" height="17" viewBox="0 0 22 17" fill="none">
                    <path
                        d="M0 10.9207C0 7.60873 2.6849 4.92383 5.9969 4.92383H10.4557C13.7676 4.92383 16.4525 7.60873 16.4525 10.9207C16.4525 14.2327 13.7676 16.9176 10.4556 16.9176H5.9969C2.6849 16.9176 0 14.2327 0 10.9207Z"
                        fill="#434343" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.4557 6.12383H5.9969C3.34764 6.12383 1.2 8.27147 1.2 10.9207C1.2 13.57 3.34764 15.7176 5.9969 15.7176H10.4556C13.1049 15.7176 15.2525 13.57 15.2525 10.9207C15.2525 8.27147 13.1049 6.12383 10.4557 6.12383ZM5.9969 4.92383C2.6849 4.92383 0 7.60873 0 10.9207C0 14.2327 2.6849 16.9176 5.9969 16.9176H10.4556C13.7676 16.9176 16.4525 14.2327 16.4525 10.9207C16.4525 7.60873 13.7676 4.92383 10.4557 4.92383H5.9969Z"
                        fill="#979797" />
                    <path
                        d="M3.70422 6.9969C3.70422 3.6849 6.38913 1 9.70112 1H14.1599C17.4719 1 20.1568 3.6849 20.1568 6.9969C20.1568 10.3089 17.4719 12.9938 14.1599 12.9938H9.70112C6.38913 12.9938 3.70422 10.3089 3.70422 6.9969Z"
                        fill="#434343" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.70112 0H14.1599C18.0242 0 21.1568 3.13262 21.1568 6.9969C21.1568 10.8612 18.0242 13.9938 14.1599 13.9938H9.70112C5.83684 13.9938 2.70422 10.8612 2.70422 6.9969C2.70422 3.13262 5.83684 0 9.70112 0ZM9.70112 1C6.38913 1 3.70422 3.6849 3.70422 6.9969C3.70422 10.3089 6.38913 12.9938 9.70112 12.9938H14.1599C17.4719 12.9938 20.1568 10.3089 20.1568 6.9969C20.1568 3.6849 17.4719 1 14.1599 1H9.70112Z"
                        fill="#D9E0E4" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.23248 6.9976C5.23248 4.53899 7.22558 2.5459 9.68418 2.5459H14.1772C16.6358 2.5459 18.6289 4.53899 18.6289 6.9976C18.6289 9.4562 16.6358 11.4493 14.1772 11.4493H9.68418C7.22558 11.4493 5.23248 9.4562 5.23248 6.9976ZM9.68418 3.1459C7.55695 3.1459 5.83248 4.87036 5.83248 6.9976C5.83248 9.12483 7.55695 10.8493 9.68418 10.8493H14.1772C16.3044 10.8493 18.0289 9.12483 18.0289 6.9976C18.0289 4.87036 16.3044 3.1459 14.1772 3.1459H9.68418Z"
                        fill="#D9E0E4" />
                </svg>
            </span>
            <span>{{ tileData.tableIdWithPlayerCount.length }}</span>
        </div>
    </div>
    <div class="penalityDiv" *ngIf="isATHEnabled && athBlockTime > 0 && tileData.maxNoOfSeats !== 2">
        <div class="penalityImg"></div>
        <img class="lock" [src]="lockicon.image.src" [alt]="lockicon.title" />
        <span class="blockedText">You are temporarily blocked from joining this Cash Game.</span>
    </div>
</div>
