import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';

import { formatCurrencyPipe } from '../../pipes/formatCurrency.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ClientToServerRequestServices } from '../../service/client-to-server-requests.service';

@Component({
    selector: 'pwc-ff-lobby-tile',
    templateUrl: 'ff-lobby-tile.component.html',
    standalone: true,
    imports: [CommonModule, TranslatePipe, formatCurrencyPipe],
    providers: [formatCurrencyPipe, TranslatePipe],
})
export class FFLobbyTileComponent {
    private readonly logger: PokerLogger;
    @Input() tileData: any;
    @Input() cardIndex: number;
    selectedIndex = -1;
    ffLobbyTileBg: any;

    @Output() cardSelected: EventEmitter<any> = new EventEmitter();

    constructor(
        loggerFactory: LoggerFactory,
        private clientToServer: ClientToServerRequestServices,
    ) {
        this.logger = loggerFactory.getLogger('ffLobbyTileLoggingComponent');
        this.ffLobbyTileBg = this.clientToServer.webAssets['lobby']['common']['fflobbytilebg'];
    }

    selectedCard(index: number) {
        this.logger.info('clicked on selectedcards..!' + JSON.stringify(index));
        this.selectedIndex = index;
        this.cardSelected.emit(this.cardIndex);
    }

    checkBlinds(blinds: any) {
        return (blinds / 100).toString().split('.').length > 1 ? (blinds / 100).toFixed(2) : blinds / 100;
    }

    getGameType(gameType: number) {
        return gameType === 0 ? "Hold'em" : gameType === 1 ? 'Omaha' : 'Omaha HL';
    }

    getLimitType(limitType: number) {
        return limitType === 0 ? 'Pot Lomit' : limitType === 1 ? 'No Limit' : 'Pot Limit';
    }

    getBuyIn(maxBuyIn: any, minBuyIn: any) {
        return maxBuyIn > 0 ? maxBuyIn : minBuyIn;
    }
}
