import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NavigationService, Page } from '@frontend/vanilla/core';
import { CurrentSessionConfig } from '@frontend/vanilla/shared/current-session';
import { RouteDataService } from '@frontend/vanilla/shared/routing';
import { Store } from '@ngrx/store';
import { LoggerFactory, PokerLogger } from '@pokercore/module/logging';
import * as _ from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { CommonGameProperties } from '../PWC-games-lib/lib/game-config/CommonGameProperties';
import serverIdConstants from '../PWC-games-lib/lib/game-config/serverIdConstants.json';
import { PWCReconnectionDialogComponent } from '../common/reconnection-popup/pwc-reconnection-dialog.component';
import { WeeklyPointsDto } from '../models/loyalty-cashback-weeklyPoints';
import { BaseGameTableConnector } from '../pgBackendConnectors/baseGameTableConnector';
import { ClassMessageListener, MessageListener } from '../pgBackendConnectors/messageListener';
import { PokerBackendConnector } from '../pgBackendConnectors/pokerBackendConnector';
import { CSD } from '../pgConnectors/sal/CSD';
import { LoginFailureResponse } from '../pgConnectors/server/common/araMessageFactory/messages/LoginFailureResponse';
import { NetRealBalance } from '../pgConnectors/server/common/araMessageFactory/messages/NetRealBalance';
import { RequestNetUserBalanceInfo } from '../pgConnectors/server/common/araMessageFactory/messages/RequestNetUserBalanceInfo';
import { ResponseNetUserBalanceInfo } from '../pgConnectors/server/common/araMessageFactory/messages/ResponseNetUserBalanceInfo';
import { TourneyCurrencyBalance } from '../pgConnectors/server/common/araMessageFactory/messages/TourneyCurrencyBalance';
import { LoginSuccessResponse } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/LoginSuccessResponse';
import { LoginSuccessUserProfile } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/LoginSuccessUserProfile';
import { MessageDeliveryFailure } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/MessageDeliveryFailure';
import { RequestTerminateLoggedOnOtherMachine } from '../pgConnectors/server/common/pgSharedMessageFactory/messages/RequestTerminateLoggedOnOtherMachine';
import { LSTabIdLastUpdatedTime } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/LSTabIdLastUpdatedTime';
import { RequestGroupLobbyUpdate } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestGroupLobbyUpdate';
import { RequestLobbyUpdate } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestLobbyUpdate';
import { RequestQuickSeatRing } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestQuickSeatRing';
import { RequestSNGJPPlayNowUpdate } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/RequestSNGJPPlayNowUpdate';
import { ResponseMyTicketsCount } from '../pgConnectors/server/common/pokerLobbyMessageFactory/messages/ResponseMyTicketsCount';
import { ATHStrikeHandCountConfiguration } from '../pgConnectors/server/common/pokerMessageFactory/messages/ATHStrikeHandCountConfiguration';
import { AddedFreeRoll } from '../pgConnectors/server/common/pokerMessageFactory/messages/AddedFreeRoll';
import { JoinPoolRequest } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinPoolRequest';
import { JoinTableQS } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinTableQS';
import { JoinTableResponse } from '../pgConnectors/server/common/pokerMessageFactory/messages/JoinTableResponse';
import { PlayerATHStrike } from '../pgConnectors/server/common/pokerMessageFactory/messages/PlayerATHStrike';
import { RemovedFreeRoll } from '../pgConnectors/server/common/pokerMessageFactory/messages/RemovedFreeRoll';
import { RequestTableLimitBalance } from '../pgConnectors/server/common/pokerMessageFactory/messages/RequestTableLimitBalance';
import { UserFreeRollList } from '../pgConnectors/server/common/pokerMessageFactory/messages/UserFreeRollList';
import { cashLobbyDataSelect, updateAthBlockTime, updateAthHandCount } from '../store/lobby.store';
import { updateIsCashBackEnabledStatus, updateLoyaltyWeeklyPoints, updateOptInStatus } from '../store/loyalty-cashback.store';
import { addMiniTable } from '../store/mini-table.store';
import {
    updateDuplicateLogin,
    updateLoginFailure,
    updateUserBalance,
    updateUserProfile,
    updateUserSSO,
    updateUserTicket,
    userBalanceSelect,
    userIsAuthenticatedSelect,
    userProfileSelect,
    userSSOSelect,
    userTicketSelect,
} from '../store/user.store';
import { EventDispatcherGlobal } from './event-despacher-global';
import { HelperClassService } from './helper';

@Injectable({ providedIn: 'root' })
@ClassMessageListener
export class ClientToServerRequestServices extends BaseGameTableConnector {
    siteCoreData = [];
    dynaconConfigs = {};
    mainLobbyBanners = {};
    webAssets = [];
    externalLinks = {};
    private pageTitle = new BehaviorSubject<string>('');
    getPageTitle: Observable<string> = this.pageTitle.asObservable();
    private eventDispatcherGlobal = new EventDispatcherGlobal();

    helperClassService = new HelperClassService();

    private requestTypeId = Number(new Date().getTime().toString().slice(-6));
    private miniTables = [];
    private autoBuyIn: any;
    private userTickets: number = 0;

    // PoolAvailabilityRequestPoolId: number;
    // PoolAvailabilityResponseArray = [];
    pendingQSReqArr: any[] = [];
    private readonly logger: PokerLogger;
    isServerGroupingEnabled: boolean;

    startTime: any = Date.now();
    secondsInterval: any;
    isUserAuthenticated = false;
    activeTime: any;
    // startTime: any = moment();
    timeInterval: any;
    presentTime: any = moment();
    currentSessionConfig = inject(CurrentSessionConfig);
    page = inject(Page);

    constructor(
        private store: Store,
        private navigation: NavigationService,
        loggerFactory: LoggerFactory,
        private routeData: RouteDataService,
        private dialog: MatDialog,
    ) {
        super(PokerBackendConnector.getInstance());
        this.logger = loggerFactory.getLogger('clienttoserverrequestsserviceLoggingComponent');
        this.eventDispatcherGlobal.addEventListener('showAssetsErrorDialog', this.showAssetsDialog.bind(this));

        this.isServerGroupingEnabled = this.routeData.getInitData().dynaconData.webClientConfigs.serverGroupingEnabled;

        this.store.select(userIsAuthenticatedSelect).subscribe((data) => {
            this.isUserAuthenticated = data;
            if (this.isUserAuthenticated === true) {
                this.startTime = Date.now();
                this.updateActiveTime();
            }
        });
        this.secondsInterval = 60 - new Date().getSeconds();
        this.currentTime();
        this.timeInterval = setInterval(() => {
            this.currentTime();
            this.updateActiveTime();
            this.secondsInterval = 60;
        }, this.secondsInterval * 1000);
        this.timeInterval = setInterval(() => {
            this.updateActiveTime();
            this.presentTime = moment().locale(this.page.culture).locale(this.page.culture).format('hh:mm A');
        }, 1000);
    }
    updateActiveTime() {
        if (this.isUserAuthenticated) {
            const loginDuration = this.currentSessionConfig.loginDuration || 0;
            const currentTime = Date.now();
            const elapsedTime = currentTime - this.startTime;
            const totalElapsedTime = elapsedTime + loginDuration;

            // Calculate hours and minutes from elapsed time
            const hours = Math.floor(totalElapsedTime / (1000 * 60 * 60));
            const minutes = Math.floor((totalElapsedTime % (1000 * 60 * 60)) / (1000 * 60));

            // Format the time duration
            this.activeTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        }
    }
    currentTime() {
        this.presentTime = moment().locale(this.page.culture).locale(this.page.culture).format('hh:mm A');
    }

    // Lobby Page Title
    updatePageTitle(newValue: string) {
        this.pageTitle.next(newValue);
    }

    SendLobbyUpdateRequest(activeType: boolean, requestType: number, tabConfigsType: string) {
        if (this.isServerGroupingEnabled && requestType != 1) {
            this.requestForGroupLobbyUpdate(activeType, requestType, tabConfigsType);
        } else {
            this.requestForLobbyData(activeType, requestType, tabConfigsType);
        }
    }

    requestForLobbyData(activeType: boolean, requestType: number, tabConfigsType: string) {
        const lobbyUpdate: RequestLobbyUpdate = new RequestLobbyUpdate();
        lobbyUpdate.setActive(activeType);
        lobbyUpdate.setRequestType(requestType); // 0 = cash, 1 = ff
        lobbyUpdate.setTabSnapshotTimeInNanosList(this.returnTabIdsArray(this.helperClassService.getTabConfigs(tabConfigsType)));

        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
        this.logger.info('ls..requestForLobbyData sent..!!!!' + JSON.stringify(lobbyUpdate));
    }

    requestForGroupLobbyUpdate(activeType: boolean, requestType: number, tabConfigsType: string) {
        const lobbyUpdate: RequestGroupLobbyUpdate = new RequestGroupLobbyUpdate();
        lobbyUpdate.setActive(activeType);
        lobbyUpdate.setRequestType(requestType); // 0 = cash, 1 = ff
        lobbyUpdate.setTabSnapshotTimeInNanosList(this.returnTabIdsArray(this.helperClassService.getTabConfigs(tabConfigsType)));
        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
    }
    requestForSpinLobbyData(domain: number, spinsType: number) {
        const lobbyUpdate: RequestSNGJPPlayNowUpdate = new RequestSNGJPPlayNowUpdate();
        lobbyUpdate.setLastSnapShotTimeInNanos(0);
        lobbyUpdate.setDomain(domain);
        lobbyUpdate.setRequestType(spinsType);
        this.sendMessageOnDomain(lobbyUpdate, CSD.REAL);
        this.logger.info('testing SPINS..!!' + JSON.stringify(lobbyUpdate));
    }

    @MessageListener('ATHStrikeHandCountConfiguration', 45814)
    responseATHStrikeHandCountConfiguration(message: ATHStrikeHandCountConfiguration) {
        this.logger.info('lresponseATHStrikeHandCountConfiguration..!!!!' + JSON.stringify(message));
        this.store.dispatch(updateAthHandCount(message['minimumHandsCount']));
    }
    @MessageListener('PlayerATHStrike', 45813)
    responsePlayerATHStrike(message: PlayerATHStrike) {
        this.logger.info('responsePlayerATHStrike..!!!!' + JSON.stringify(message));
        this.store.dispatch(updateAthBlockTime(message['athBlockExpireTime']));
    }

    returnTabIdsArray(tabIds: Array<number>) {
        this.logger.info('returnTabIdsArray..!!!!' + JSON.stringify(tabIds));
        const arr: any[] = [];
        tabIds.forEach((id) => {
            const tabId: LSTabIdLastUpdatedTime = new LSTabIdLastUpdatedTime();
            tabId.setTabId(id);
            tabId.setSnapShotTimeInNanos(0);
            arr.push(tabId);
        });
        return arr;
    }

    /********************************************************************************
     ************************* Request for Cash Games ********************************
     ********************************************************************************/
    getPrevTableIds() {
        const tableIds: any[] = [];
        this.miniTables.forEach((res: any) => tableIds.push(res.tableId));
        return tableIds;
    }

    onPrimaryAssetsLoaded() {
        this.processPendingQSReq();
    }

    processPendingQSReq = function () {
        for (const requestId in this.pendingQSReqArr) {
            const obj = this.pendingQSReqArr[requestId];
            this.requestForQuickSeatRing(obj, obj.autoBuyIn);
        }
        this.pendingQSReqArr = [];
    };
    cashGameBuyinRequest(data: any, buyInValues: any) {
        this.logger.info('cashGameBuyinRequest..!!' + JSON.stringify(data, buyInValues));
        this.requestTypeId++;
        this.requestForQuickSeatRing(data, buyInValues);
    }
    requestForQuickSeatRing(data: any, buyInValues: any) {
        if (!CommonGameProperties.primaryAssetsLoaded) {
            this.pendingQSReqArr[this.requestTypeId] = data;
            this.showAssetsDialog();
            return;
        }
        const quickSeatRing: RequestQuickSeatRing = new RequestQuickSeatRing();
        quickSeatRing.setRequestId(this.requestTypeId);
        quickSeatRing.setTableIdList(this.getPrevTableIds());
        quickSeatRing.setGameType(data.gameTypeId);
        quickSeatRing.setLimitType(data.tableLimitType);
        quickSeatRing.setLowerStakesOrBlinds(data.tableLimitType === 0 ? data.stakesLowerLimit : data.smallBlind);
        quickSeatRing.setMaxSeats(data.maxNoOfSeats);
        quickSeatRing.setSpeedType(data.gamePlayType);
        quickSeatRing.setSelectedBuyIn(buyInValues.selectedBuyIn * 100);
        quickSeatRing.setMinBuyIn(data.minBuyInAmount);
        quickSeatRing.setNoOfTables(buyInValues.noOfGamesSelected);
        quickSeatRing.setAnonymousFlag(0);
        quickSeatRing.setActionType(3);
        quickSeatRing.setCardId(-1);
        quickSeatRing.setBeginnerFlag(0);
        quickSeatRing.setPrevSearchedSeats([]);
        quickSeatRing.setProtectionLevel(data.protectionLevel);
        quickSeatRing.setActionButtonId(data.tableProfileType ? 3 : 0);
        quickSeatRing.setSubLiquidityType(data.subLiquidityType);
        quickSeatRing.setRealNameTable(data.realNameTable);
        quickSeatRing.setAnteAmount(data.anteCashAmount);
        quickSeatRing.setRestrictedRoomId(data.restrictedRoomId);
        // quickSeatRing.setClubId(0);
        // for group tables
        if (this.isServerGroupingEnabled) {
            quickSeatRing.setNoOfSeats(buyInValues.noOfGamesSelected);
            quickSeatRing.setBuyInAmount(buyInValues.selectedBuyIn * 100);
        }
        quickSeatRing.setUpperStakesOrBlinds(data.tableLimitType === 0 ? data.stakesUpperLimit : data.bigBlind);
        quickSeatRing.setRebuyToMax(buyInValues.autoBuyIn);

        this.sendMessageOnDomain(quickSeatRing, CSD.REAL);
        this.logger.info('request For QuickSeatRing..!!!!' + JSON.stringify(quickSeatRing));

        this.autoBuyIn = buyInValues.autoBuyIn;
    }

    @MessageListener('ResponseQuickSeatRing', 86137)
    getResponseQuickSeatRing(message: any, classId, peerID) {
        this.logger.info('ls..getResponseQuickSeatRing..!!!!' + JSON.stringify(message, classId, peerID));
        let listOfTables;
        this.store.select(cashLobbyDataSelect).subscribe((data) => {
            listOfTables = data;
        });
        this.logger.info('QuickSeatRing listOfTables..!!!!' + JSON.stringify(listOfTables));

        if (message.responseCode === 0) {
            message.resultList.forEach((result) => {
                if (result.errorCode !== 0) {
                    if (result.errorCode != 118) {
                        this.eventDispatcherGlobal.dispatchEvent('ResponseQuickSeatRingError', result.errorCode);
                    }
                } else {
                    const message = {};
                    message['requestType'] = this.isServerGroupingEnabled.toString() === 'true' ? 2 : 1; //REQ_TYPE_JOINTABLE_QS_SHOW_BUYIN = 2, QSREQUESTTYPE.REQ_TYPE_JOINTABLE_QS_AUTOSEAT = 1;
                    message['conversationId'] = 0;

                    const cardDetails = _.find(listOfTables, { tableInfoId: result.tableInfoId });
                    message['tableId'] = result.tableId;
                    message['tableTypeId'] = cardDetails['tableTypeId'];
                    message['tableCategory'] = cardDetails['tableCategory'];
                    this.logger.info('ls..requestForJoinTableQS111..!!!!' + JSON.stringify(message));
                    this.requestForJoinTableQS(message);
                }
            });
        } else {
            this.eventDispatcherGlobal.dispatchEvent('ResponseQuickSeatRingError', message);
        }
    }

    requestForJoinTableQS(data: any) {
        const joinTableQS: JoinTableQS = new JoinTableQS();
        joinTableQS.setConversationId(data.conversationId);
        joinTableQS.setRequestType(data.requestType);
        joinTableQS.setTableCategory(data.tableCategory);
        joinTableQS.setReqServerPeerId(data.tableId);

        this.sendMessageOnDomain(joinTableQS, CSD.REAL);
        this.logger.info('ls..requestForJoinTableQS..!!!!' + JSON.stringify(joinTableQS));
    }

    @MessageListener('JoinTableResponse', 45207)
    getJoinTableResponse(message: JoinTableResponse) {
        if (message && message.getTableStatus()) {
            const tableStatus = message.getTableStatus();
            if (
                tableStatus &&
                tableStatus['tableCategory'] === serverIdConstants.TABLE_CATEGORY_TOURNAMENT &&
                tableStatus['tournamentCategory'] !== 3
            ) {
                return;
            }
        }
        if (message['responseId'] === 108 || message['responseId'] === 202) {
            this.logger.info('getJoinTableResponse..!!!!' + JSON.stringify(message));
            this.store.dispatch(addMiniTable({ miniTables: message.getTableStatus() }));
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
            this.autoRebuyStatusChange(message['reqServerPeerId'], message.getTableStatus()!.getTableId(), this.autoBuyIn);
            // this.navigation.goTo('/play/game-table');
        } else {
            this.eventDispatcherGlobal.dispatchEvent('JoinTableResponseError', message);
        }
    }

    autoRebuyStatusChange(peerId, entryId, autoRebuyStatus) {
        const message = {};
        message['className'] = 'autoRebuyStatusChange';
        message['peerId'] = peerId;
        message['rebuyStatus'] = {
            entryId: entryId,
            autoRebuyStatus: autoRebuyStatus,
        };
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    /********************************************************************************
     **************************** End Cash Games *************************************
     ********************************************************************************/

    /********************************************************************************
     ********************** Request for Add Similar Game Table ***********************
     ********************************************************************************/

    addSimilarGameTable(activeTable: any) {
        this.logger.info('ls..addSimilarGameTable..!!!!' + JSON.stringify(activeTable));
        let buyInValues: any;
        if (activeTable.from === 'fast-forward') {
            buyInValues = {
                noOfGamesSelected: 1,
                selectedBuyIn: activeTable.tableObject.tableStatus.maxBuyin, //obj['formattedBuyIn'],
                autoBuyIn: false,
            };
            // this.joinPoolRequest(activeTable.tableObject.poolID, buyInValues);
            const joinPool: JoinPoolRequest = new JoinPoolRequest();

            joinPool.setNoOfEntries(buyInValues.noOfGamesSelected);
            joinPool.setBuyInAmount(buyInValues.selectedBuyIn);
            joinPool.setRebuyToMax(buyInValues.autoBuyIn);
            joinPool.setReqServerPeerId(activeTable.tableObject.poolID);

            this.sendMessageOnDomain(joinPool, CSD.REAL);
        } else if (activeTable.from === 'cash') {
            buyInValues = {
                noOfGamesSelected: 1,
                selectedBuyIn: activeTable.tableObject.maxBuyInAmount / 100,
                autoBuyIn: false,
            };
            this.cashGameBuyinRequest(activeTable.tableObject, buyInValues);
        } else if (activeTable.from === 'spins-overdrive') {
            this.requestForSpinLobbyData(0, 1);
            const message = {};
            message['className'] = 'AddSimilarGameTable';
            message['gameCount'] = 1;
            message['enableExpoSpin'] = true;
            CommonGameProperties.facade['sendOpenTableReq'](message);
        }
    }

    goToLobby(lobby: string) {
        // this.isNewGameTableOpen = false;
        this.navigation.goTo(`/play/${lobby}`);
    }

    /********************************************************************************
     **************************** End Similar Table Request  *************************
     ********************************************************************************/

    @MessageListener('LoginSuccessResponse', 41203)
    loginSuccessResponse(message: LoginSuccessResponse) {
        this.logger.info('ls..PWC--LoginSuccessResponse..!!!!' + JSON.stringify(message));
        this.requestNetUserBalanceInfo();
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        this.store.dispatch(updateUserSSO(message.getSsoKey()!));
        CommonGameProperties.ssokey = { key: message.getSsoKey() };
        this.requestTableLimitBalance();
    }

    @MessageListener('LoginSuccessUserProfile', 41204)
    loginSuccessUserProfile(message: LoginSuccessUserProfile) {
        this.logger.info('ls..PWC--LoginSuccessUserProfile..!!!!' + JSON.stringify(message));
        //this.eventDispatcherGlobal.dispatchEvent("gameEventListener",message);
        this.createSocialConnection();
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        this.store.dispatch(updateUserProfile(message.getUserProfile()));
    }

    @MessageListener('LoginFailureResponse', 41200)
    loginFailureResponse(message: LoginFailureResponse) {
        this.store.dispatch(updateLoginFailure(true));
        this.logger.error(' loginFailureResponse..!!!!' + JSON.stringify(message));
    }

    @MessageListener('RequestTerminateLoggedOnOtherMachine', 41076)
    requestTerminateLoggedOnOtherMachine(message: RequestTerminateLoggedOnOtherMachine) {
        this.logger.info('RequestTerminateLoggedOnOtherMachine' + JSON.stringify(message));
        this.store.dispatch(updateDuplicateLogin(true));
    }

    @MessageListener('MessageDeliveryFailure', 28686)
    messageDeliveryFailure(message: MessageDeliveryFailure) {
        this.logger.error('messageDeliveryFailure..!!!!' + JSON.stringify(message));
    }

    requestNetUserBalanceInfo() {
        const userBalanceRequest = new RequestNetUserBalanceInfo();
        this.sendMessageOnDomain(userBalanceRequest, CSD.REAL);
    }

    @MessageListener('ResponseNetUserBalanceInfo', 41221)
    ResponseNetUserBalanceInfo(message: ResponseNetUserBalanceInfo) {
        this.logger.info('ResponseNetUserBalanceInfo..!!!!' + JSON.stringify(message));
        this.store.dispatch(updateUserBalance(message));
    }

    @MessageListener('NetRealBalance', 41219)
    ResponseNetRealBalance(message: NetRealBalance) {
        this.logger.info('ResponseNetRealBalance..!!!!' + JSON.stringify(message));
        let balanceInfo: any;
        this.store.select(userBalanceSelect).subscribe((balance) => {
            balanceInfo = Object.assign({}, balance);
        });
        balanceInfo['netRealBalance'] = message;
        this.store.dispatch(updateUserBalance({ ...balanceInfo, netRealBalance: message }));
    }

    @MessageListener('TourneyCurrencyBalance', 41215)
    ResponseTourneyCurrencyBalance(message: TourneyCurrencyBalance) {
        let balanceInfo: any;
        this.store.select(userBalanceSelect).subscribe((balance) => {
            balanceInfo = Object.assign({}, balance);
        });
        balanceInfo['tourneyCurrencyBalance'] = message;
        this.store.dispatch(updateUserBalance({ ...balanceInfo, tourneyCurrencyBalance: message }));
    }

    @MessageListener('UserFreeRollList', 45653)
    userFreeRollList(message: UserFreeRollList) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        if (message['freeRollIds']) {
            this.store.dispatch(updateUserTicket(message['freeRollIds'].length));
        }
    }

    @MessageListener('ResponseMyTicketsCount')
    ResponseMyTicketsCount(message: ResponseMyTicketsCount) {
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
        // this.store.dispatch(updateUserTicket(message));
    }

    @MessageListener('AddedFreeRoll')
    AddedFreeRoll(message: AddedFreeRoll) {
        this.store.select(userTicketSelect).subscribe((userTicket) => {
            this.userTickets = userTicket;
        });
        this.store.dispatch(updateUserTicket(this.userTickets + 1));
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('RemovedFreeRoll')
    RemovedFreeRoll(message: RemovedFreeRoll) {
        this.store.select(userTicketSelect).subscribe((userTicket) => {
            this.userTickets = userTicket;
        });
        this.store.dispatch(updateUserTicket(this.userTickets - 1));
        this.eventDispatcherGlobal.dispatchEvent('gameEventListener', message);
    }

    @MessageListener('NotificationMessage', 998566)
    updateLoyaltyResponse(message: any) {
        if (
            message.className === 'LOYALTY_NOTIFICATION_EVENT' &&
            this.dynaconConfigs['dynaconData']['webClientConfigs']['loyaltyCashBack']['isLoyaltyEnabled'] === true
        ) {
            const response = JSON.parse(message['pl']).data;
            const res = {
                targetCashback: response.tcv1,
                currentCashback: response.ccv1,
                weeklyPoints: response.cpV1,
                currentTarget: response.tpv1,
                nextTargetCashback: response.ntc,
                nextSlabPoints: response.tspV1,
                currentSlabPoints: response.cspV1,
                segmentMultiplier: response.segmentMultiplier,
                isOptin: response.opt,
                endDate: JSON.stringify(response.wem),
                currency: response.tc.charAt(0),
                segmentId: response.segmentId,
                startDate: JSON.stringify(response.startDate),
                additionalParams: [],
                // pointsRequiredForNextSlab: null
            } as WeeklyPointsDto;

            this.store.dispatch(updateIsCashBackEnabledStatus({ isCashBackEnabled: true }));
            this.store.dispatch(updateLoyaltyWeeklyPoints({ weeklyPoints: res }));
            this.store.dispatch(updateOptInStatus({ optIn: { status: res.isOptin === 'Y' ? true : false } }));
            // window.AppController.LCBProgressBarBoosterUpdate(response);
            const obj = {};
            obj['className'] = 'LCBProgressBarBoosterUpdate';
            obj['data'] = res;
            this.eventDispatcherGlobal.dispatchEvent('gameEventListener', obj);
        } else {
            this.store.dispatch(updateIsCashBackEnabledStatus({ isCashBackEnabled: false }));
            this.store.dispatch(updateLoyaltyWeeklyPoints({ weeklyPoints: {} }));
            this.store.dispatch(updateOptInStatus({ optIn: { status: false } }));
        }
    }

    requestTableLimitBalance() {
        const tableLimitBalance = new RequestTableLimitBalance();
        this.sendMessageOnDomain(tableLimitBalance, CSD.REAL);
    }

    openDepositPage(pageType: string) {
        let ssokey, userId, langId;
        combineLatest([this.store.select(userSSOSelect), this.store.select(userProfileSelect)]).subscribe(([userSSO, userProfile]) => {
            ssokey = userSSO;
            userId = userProfile['accountName'];
            langId = userProfile['accountLanguageId'];
        });
        this.logger.info(
            `deposite page URL: ${this.externalLinks[pageType]['titleLink']['url']}&userId=${userId}&langId=${langId}&sessionKey=${ssokey}`,
        );
        window.open(`${this.externalLinks[pageType]['titleLink']['url']}&userId=${userId}&langId=${langId}&sessionKey=${ssokey}`, '_self');
    }

    showAssetsDialog() {
        const data = {
            title: 'Network Error', //this.translatePipe.transform('PARTY_POKER_COMMON_ERROR'),
            message: 'Assets are not loaded, do you want to reload?',
            buttons: [
                { text: 'No', result: 'No' },
                { text: 'Yes', result: 'Yes' },
            ], //[{ text: this.translatePipe.transform('PARTY_POKER_MOBILE_LOGIN_OK') }]
        };
        this.dialog.open(PWCReconnectionDialogComponent, {
            width: '75%',
            maxHeight: '100vh',
            data: data,
            autoFocus: false,
            panelClass: 'cg-error-wrapper',
            hasBackdrop: true,
            backdropClass: 'cg-error-overlay',
            disableClose: false,
            closeOnNavigation: true,
        });
    }
}
