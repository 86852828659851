<div class="tile-card-container" (click)="selectedCard(cardIndex)" [attr.index]="cardIndex">
    <div class="tile-card-header">
        <h4 class="title-card-title">
            <small>{{ 'PARTY_POKER_GC_GAMELOBBY_BLINDSCOL' | translate }}:</small> {{ tileData.smallBlindAmount | formatCurrencyPipe: 'USD' }}/{{
                tileData.bigBlindAmount | formatCurrencyPipe: 'USD'
            }}
        </h4>
    </div>
    <div class="tile-card-body">
        <div class="tile-stakes-ante">
            <span class="ff-stakes">{{ getLimitType(tileData.limitType) }} {{ getGameType(tileData.gameType) }}</span>
        </div>
        <div class="tile-card-buyIn-container">
            <div class="tile-card-buyIn-background">
                <img class="ffLobbyTileBg" [src]="ffLobbyTileBg.image.src" [alt]="ffLobbyTileBg.title" />
            </div>
            <div class="tile-card-buyIn-buyIn">
                <span style="height: 15px"
                    ><span class="tile-card-realName" *ngIf="tileData.realNameTable">{{ 'PARTY_POKER_MOBILE_CASH_REALNAME' | translate }}</span></span
                >
                <div class="tile-card-text">
                    <span>{{ 'PARTY_POKER_GC_BUYINCHIPS_TITLE' | translate }}</span>
                    <span>{{ getBuyIn(tileData.maxBuyInAmount, tileData.minBuyInAmount) | formatCurrencyPipe: 'USD' }}</span>
                </div>
                <span class="tile-card-maxSeats">{{ tileData.noOfSeats }} {{ 'PARTY_POKER_GC_QSLOBBY_FAVSEATS' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="tile-card-footer justify-content-center">
        <div class="tile-card-players">
            <span>{{ tileData.playersCount }}</span>
            <span class="players-text">{{ 'PARTY_POKER_GC_GAMETABLE_TRNYINFO_PLAYERS' | translate }}</span>
        </div>
    </div>
</div>
